<script setup>
import StaffLayout from '@/Layouts/StaffLayout.vue';

import DataCard from '@/Components/DataCard.vue';
import { useI18n } from 'vue-i18n';

import Button from '@/Components/Button/Button.vue';
import DetailsCard from '@/Components/Flexer/DetailsCard.vue';
import LinkButton from '@/Components/Link/LinkButton.vue';
import LocationDetailsCard from '@/Components/Location/DetailsCard.vue';
import Modal from '@/Components/Popup/Modal.vue';
import Rating from '@/Components/Rating.vue';
import { ref } from 'vue';

import { useNavigationStore } from '@/stores/navigation';

const { t } = useI18n();

const props = defineProps({
    review: Object,
});
const navigation = useNavigationStore();
const confirmDeletion = ref(false);
const confirmNullify = ref(false);
</script>

<template>
    <StaffLayout :title="$t('Reviews')">
        <template #sidebar>
            <DetailsCard v-if="review.reviewer_type.includes('User')" :flexer="review.reviewer" />
            <LocationDetailsCard v-if="review.reviewer_type.includes('Location')" :location="review.reviewer" />
            <h4 class="font-bold">{{ $t('About') }}</h4>
            <DetailsCard v-if="review.subject_type.includes('User')" :flexer="review.subject" />
            <LocationDetailsCard v-if="review.subject_type.includes('Location')" :location="review.subject" />
        </template>

        <template #default>
            <DataCard :title="$t('Review')">
                <p>{{ review.review }}</p>
                <Rating :value="review.rating" with-text />
            </DataCard>

            <div class="flex gap-4">
                <Button
                    warning
                    @click="confirmNullify = true"
                    icon="/icon/block-white.svg"
                    :text="
                        $t('Remove {attribute}, keep {second_attribute}', {
                            attribute: $t('review'),
                            second_attribute: $t('rating'),
                        })
                    "
                />
                <Button danger @click="confirmDeletion = true" icon="/icon/block-white.svg" :text="$t('Delete')" />
            </div>
        </template>
    </StaffLayout>
    <Modal v-model:open="confirmDeletion">
        <template #default>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae reiciendis laudantium beatae eligendi,
            dolores amet expedita temporibus animi tempore esse. Labore aperiam reprehenderit facere rem, earum at neque
            sit consequuntur.
        </template>
        <template #button-bar>
            <LinkButton
                danger
                :href="route('staff.reviews.destroy', { id: review.id })"
                method="delete"
                :data="{ redirect: navigation.history[1] }"
                icon="/icon/block-white.svg"
                :text="$t('Delete')"
            />
            <Button white @click="confirmDeletion = false">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
    <Modal v-model:open="confirmNullify">
        <template #default>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae reiciendis laudantium beatae eligendi,
            dolores amet expedita temporibus animi tempore esse. Labore aperiam reprehenderit facere rem, earum at neque
            sit consequuntur.
        </template>
        <template #button-bar>
            <LinkButton
                v-if="review.review"
                warning
                method="delete"
                :data="{ redirect: navigation.history[1], action: 'nullify_rating' }"
                icon="/icon/block-white.svg"
                :href="route('staff.reviews.destroy', { id: review.id })"
                :text="
                    $t('Remove {attribute}, keep {second_attribute}', {
                        attribute: $t('review'),
                        second_attribute: $t('rating'),
                    })
                "
            />
            <Button white @click="confirmNullify = false">
                {{ $t('Cancel') }}
            </Button>
        </template>
    </Modal>
</template>
