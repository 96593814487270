<script setup>
import LinkButton from '@/Components/Button/Link.vue';
import MediaWithPopup from '@/Components/MediaWithPopup.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
    flexer: Object,
});
</script>

<template>
    <div class="aspect-h-1 aspect-w-1 h-0 w-full overflow-hidden rounded bg-white">
        <MediaWithPopup
            v-if="flexer.profile_media"
            :media="flexer.profile_media"
            classes="object-cover w-full h-full"
        />
        <div
            :class="{
                'opacity-0': !flexer.restricted,
            }"
            class="pointer-events-none z-10 flex items-center justify-center bg-red/60 text-4 font-bold uppercase text-white"
        >
            {{ t('Blocked') }}
        </div>
    </div>

    <LinkButton
        :href="`tel:${flexer.phone_number}`"
        :text="flexer.phone_number"
        icon="/icon/chat.svg"
        white
    />

    <LinkButton
        :href="`mailto:${flexer.email}`"
        :text="flexer.email"
        icon="/icon/mail.svg"
        white
    />
    <LinkButton
        href=""
        icon="/icon/chat.svg"
        text="not implemented yet"
        white
    />

    <slot />
</template>
