<script setup>
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';

console.error('Use @/Components/Button/Link.vue');

const props = defineProps({
    icon: {
        type: String,
        required: false,
    },
    iconRight: {
        type: Boolean,
        required: false,
    },
    text: {
        type: String,
        required: true,
    },
    href: {
        type: String,
        required: true,
    },
    danger: {
        type: Boolean,
        required: false,
        default: false,
    },
    warning: {
        type: Boolean,
        required: false,
        default: false,
    },
    white: {
        type: Boolean,
        required: false,
        default: false,
    },
    disabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    method: {
        type: String,
        required: false,
        default: 'get',
    },
    data: {
        type: Object,
        required: false,
    },
    asA: Boolean,
});

const as = computed(() => {
    return props.method == 'get' ? 'a' : 'button';
});

const isMailOrTel = computed(() => {
    return props.href.includes('mailto:') || props.href.includes('tel:') || props.asA;
});

const component = computed(() => {
    return isMailOrTel.value ? 'a' : Link;
});
</script>

<template>
    <component
        :is="component"
        :as="as"
        :data="data"
        :method="method"
        :href="href"
        :class="{
            'bg-green-700 hover:bg-green-500 focus:ring-green-500 text-white ': !danger && !warning && !white,
            'bg-red hover:bg-red-700 focus:ring-red text-white ': danger,
            'bg-orange hover:bg-orange focus:ring-orange text-white': warning,
            'bg-white hover:bg-white focus:ring-green-500 text-black border-gray-800': white,
            'disabled:bg-gray-500-400': disabled,
        }"
        class="flex items-center w-full gap-3 px-4 py-3 leading-tight transition-colors rounded min-h-10 focus:ring-2 focus:ring-offset-2"
    >
        <span v-if="icon && !iconRight" class="flex items-center justify-center min-w-4">
            <img class="w-4 h-4" :src="icon" alt="" />
        </span>
        <span class="flex-1 text-left break-all">
            {{ text }}
        </span>
        <span v-if="icon && iconRight" class="flex items-center justify-center min-w-4">
            <img class="w-4 h-4" :src="icon" alt="" />
        </span>
    </component>
</template>
